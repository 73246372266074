var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            size: "small",
            "header-cell-style": {
              background: "#d9dde1",
              color: "#0d0202",
            },
          },
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "模板id" } }),
          _c("el-table-column", { attrs: { prop: "name", label: "模板名称" } }),
          _c("el-table-column", {
            attrs: { prop: "area", label: "配送区域" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.areaList &&
                            row.areaList.map((e) => e.fullName).join("/")
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "计费方式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          ["包邮", "按件计费", "按重量计费"][
                            Number(scope.row.billType)
                          ]
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "首件数/首重" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          Number(scope.row.billType) === 0
                            ? "-"
                            : Number(scope.row.billType) === 1
                            ? scope.row.firstNum + "(件)"
                            : scope.row.firstNum + "(kg)"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "首费" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          Number(scope.row.billType) === 0
                            ? "-"
                            : scope.row.firstFee
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "shipCondition", label: "包邮条件" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          Number(scope.row.billType) === 0
                            ? "-"
                            : scope.row.isShip == 0
                            ? "-"
                            : scope.row.isFee == 1
                            ? "满" + scope.row.shipCondition + "元包邮"
                            : "满" +
                              scope.row.shipCondition +
                              (Number(scope.row.billType) === 1 ? "件" : "kg") +
                              "包邮"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "110", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        staticClass: "margin_right_10",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.operation("edit", scope.row)
                          },
                        },
                      },
                      [_vm._v(" 修改 ")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticClass: "margin_right_10",
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.operation("delete", scope.row)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }