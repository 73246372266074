<template>
  <div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      style="width: 100%"
      size="small"
      :header-cell-style="{
        background: '#d9dde1',
        color: '#0d0202'
      }"
    >
      <el-table-column
        prop="id"
        label="模板id"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="模板名称"
      >
      </el-table-column>
      <el-table-column
        prop="selfName"
        label="自提点名称"
      >
      </el-table-column>
      <el-table-column
        prop="phone"
        label="自提点电话"
      >
      </el-table-column>
      <el-table-column
        prop="address"
        label="自提点位置"
      >
      </el-table-column>

      <el-table-column
        label="操作"
        width="110"
      >
        <template slot-scope="scope">
          <el-link
            class="margin_right_10"
            type="primary"
            @click="operation('edit', scope.row)"
          >
            修改
          </el-link>
          <el-link
            class="margin_right_10"
            type="danger"
            @click="operation('delete', scope.row)"
          >
            删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import qs from 'qs'
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      storeId: ''
    }
  },
  created() {
    this.storeId = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).storeList && JSON.parse(localStorage.getItem('userInfo')).storeList[0] ? JSON.parse(localStorage.getItem('userInfo')).storeList[0].storeId : localStorage.getItem('defaultStoreId')
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      this.$axios
        .get(this.$api.trade_template_mail.list, {
          params: {
            templateType: 3,
            storeId: this.storeId
          }
        })
        .then((res) => {
          if (res.code === 0) {
            this.tableData = res.data
          } else {
            this.tableData = []
          }
          this.loading = false
        })
    },
    operation(type, row) {
      if (type === 'edit') {
        this.$router.push({
          path: '/nb/goods/freight/self',
          query: {
            id: row.id,
            templateType: row.templateType,
            type: 'edit',
            storeId: this.storeId
          }
        })
      } else if (type === 'delete') {
        console.log(row)
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$axios
              .post(
                this.$api.trade_template_self.remove,
                qs.stringify({
                  id: row.id
                })
              )
              .then((res) => {
                if (res.code === 0) {
                  this.$message({ type: 'success', message: '操作成功！' })
                  this.getList()
                }
              })
          })
          .catch(() => {})
      }
    }
  }
}
</script>

<style>
</style>
