<template>
  <div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      style="width: 100%"
      size="small"
      :header-cell-style="{
        background: '#d9dde1',
        color: '#0d0202',
      }"
    >
      <el-table-column
        prop="id"
        label="模板id"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="模板名称"
      >
      </el-table-column>
      <el-table-column
        prop="area"
        label="配送区域"
      >
        <template slot-scope="{row}">
          {{ row.areaList && row.areaList.map(e => e.fullName).join('/') }}
        </template>
      </el-table-column>
      <el-table-column label="计费方式">
        <template slot-scope="scope">
          {{ ["包邮", "按件计费", "按重量计费"][Number(scope.row.billType)] }}
        </template>
      </el-table-column>
      <el-table-column label="首件数/首重">
        <template slot-scope="scope">
          {{
            Number(scope.row.billType) === 0
              ? "-"
              : Number(scope.row.billType) === 1
                ? scope.row.firstNum + "(件)"
                : scope.row.firstNum + "(kg)"
          }}
        </template>
      </el-table-column>
      <el-table-column label="首费">
        <template slot-scope="scope">
          {{ Number(scope.row.billType) === 0 ? "-" : scope.row.firstFee }}
        </template>
      </el-table-column>
      <el-table-column
        prop="shipCondition"
        label="包邮条件"
      >
        <template slot-scope="scope">
          {{

            Number(scope.row.billType) === 0
              ? "-"
              : scope.row.isShip == 0
                ? '-'
                : scope.row.isFee == 1
                  ? "满" + scope.row.shipCondition + "元包邮"
                  : "满" +
                    scope.row.shipCondition +
                    (Number(scope.row.billType) === 1 ? "件" : "kg") +
                    "包邮"
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="isFee" label="是否按金额包邮"> </el-table-column> -->
      <el-table-column
        label="操作"
        width="110"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-link
            class="margin_right_10"
            type="primary"
            @click="operation('edit', scope.row)"
          >
            修改
          </el-link>
          <el-link
            class="margin_right_10"
            type="danger"
            @click="operation('delete', scope.row)"
          >
            删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import qs from 'qs';
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      storeId: ''
    };
  },
  created() {
    this.storeId =
      localStorage.getItem('userInfo') &&
      JSON.parse(localStorage.getItem('userInfo')).storeList &&
      JSON.parse(localStorage.getItem('userInfo')).storeList[0]
        ? JSON.parse(localStorage.getItem('userInfo')).storeList[0].storeId
        : localStorage.getItem('defaultStoreId');
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.$axios
        .get(this.$api.trade_template_mail.list, {
          params: {
            templateType: 2,
            storeId: this.storeId
          }
        })
        .then((res) => {
          if (res.code === 0) {
            let newArray = [];
            res.data &&
              res.data.forEach((item) => {
                item.tradeBillCommonDtoList &&
                  newArray.push({
                    ...item.tradeBillCommonDtoList[0],
                    ...item,
                    billId: item.tradeBillCommonDtoList[0].id
                  });
              });
            this.tableData = newArray;
          } else {
            this.tableData = [];
          }
          this.loading = false;
        });
    },
    operation(type, row) {
      if (type === 'edit') {
        this.$router.push({
          path: '/nb/goods/freight/city',
          query: {
            id: row.id,
            templateType: row.templateType,
            type: 'edit',
            storeId: this.storeId
          }
        });
      } else if (type === 'delete') {
        console.log(row);
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$axios
              .post(
                this.$api.trade_template_mail.remove,
                qs.stringify({
                  billId: row.billId,
                  id: row.id
                })
              )
              .then((res) => {
                if (res.code === 0) {
                  this.$message({ type: 'success', message: '操作成功！' });
                  this.getList();
                }
              });
          })
          .catch(() => {});
      }
    }
  }
};
</script>

<style>
</style>
