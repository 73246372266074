<template>
  <div class="fee-template">
    <div class="nav-bar bgf">
      <el-alert
        title="运费模板设置注意事项："
        type="warning"
        class="notify"
        :closable="false"
        show-icon
      >
        <div v-html="notify || '暂无'">
        </div>
      </el-alert>
    </div>

    <div class="wrap bgf">
      <el-radio-group
        v-model="freightType"
        class="margin_bottom_30"
        size="small"
        @change="changeTab"
      >
        <el-radio-button :label="1">
          普通快递
        </el-radio-button>
        <el-radio-button :label="2">
          同城配送
        </el-radio-button>
        <el-radio-button :label="3">
          自提
        </el-radio-button>
      </el-radio-group>
      <div class="margin_bottom_10">
        <el-button
          type="primary"
          size="mini"
          @click="addFreight"
        >
          新增模板
        </el-button>
      </div>
      <div>
        <CommonTable v-if="freightType === 1"></CommonTable>
        <CityTable v-if="freightType === 2"></CityTable>
        <SelfTable v-if="freightType === 3"></SelfTable>
      </div>
    </div>

    <ChangeStore
      :show-change-store="showChangeStore"
      :url="url"
      :query="{ type: 'add' }"
      @closeBox="showChangeStore = false"
    ></ChangeStore>
  </div>
</template>

<script>
import ChangeStore from '@/components/common/changeStore.vue';
import './style.scss';
import CommonTable from './components/commonTable';
import CityTable from './components/cityTable';
import SelfTable from './components/selfTable';

export default {
  components: {
    CommonTable,
    CityTable,
    SelfTable,
    ChangeStore
  },
  data() {
    return {
      freightType: 1,
      url: '',
      showChangeStore: false,
      storeId: '',
      notify: '' // 顶部通知内容
    };
  },
  created() {
    if (this.$route.query.freightType) {
      this.freightType = Number(this.$route.query.freightType);
    }
  },
  mounted() {
    this.storeId =
      localStorage.getItem('userInfo') &&
      JSON.parse(localStorage.getItem('userInfo')).storeList &&
      JSON.parse(localStorage.getItem('userInfo')).storeList[0]
        ? JSON.parse(localStorage.getItem('userInfo')).storeList[0].storeId
        : localStorage.getItem('defaultStoreId');

    // 获取通知
    this.getNotify();
  },
  methods: {
    getNotify() {
      this.$axios
        .post(this.$api.trade_template_mail.getNotify, { keyName: 'template' }).then(res => {
          if (res.code === 0) {
            this.notify = res.data;
          } else {
            this.notify = '';
          }
        })
    },
    changeTab(e) {
      this.freightType = e;
      this.$router.push({
        query: {
          freightType: e
        }
      });
    },
    addFreight() {
      let url;
      if (this.freightType === 1) {
        url = '/nb/goods/freight/common';
      } else if (this.freightType === 2) {
        url = '/nb/goods/freight/city';
      } else if (this.freightType === 3) {
        url = '/nb/goods/freight/self';
      }
      this.$router.push({
        path: url,
        query: {
          ...this.query,
          type: 'add',
          storeId: this.storeId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.fee-template {
  .nav-bar {
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 3px;
  }
  .wrap {
    padding: 15px;
    border-radius: 3px;
  }
}
</style>

<style scoped>
  .notify >>> .el-alert__description {
    line-height: 1.5;
  }
</style>
