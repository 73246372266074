var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fee-template" },
    [
      _c(
        "div",
        { staticClass: "nav-bar bgf" },
        [
          _c(
            "el-alert",
            {
              staticClass: "notify",
              attrs: {
                title: "运费模板设置注意事项：",
                type: "warning",
                closable: false,
                "show-icon": "",
              },
            },
            [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.notify || "暂无") },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap bgf" },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "margin_bottom_30",
              attrs: { size: "small" },
              on: { change: _vm.changeTab },
              model: {
                value: _vm.freightType,
                callback: function ($$v) {
                  _vm.freightType = $$v
                },
                expression: "freightType",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: 1 } }, [
                _vm._v(" 普通快递 "),
              ]),
              _c("el-radio-button", { attrs: { label: 2 } }, [
                _vm._v(" 同城配送 "),
              ]),
              _c("el-radio-button", { attrs: { label: 3 } }, [
                _vm._v(" 自提 "),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "margin_bottom_10" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.addFreight },
                },
                [_vm._v(" 新增模板 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.freightType === 1 ? _c("CommonTable") : _vm._e(),
              _vm.freightType === 2 ? _c("CityTable") : _vm._e(),
              _vm.freightType === 3 ? _c("SelfTable") : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("ChangeStore", {
        attrs: {
          "show-change-store": _vm.showChangeStore,
          url: _vm.url,
          query: { type: "add" },
        },
        on: {
          closeBox: function ($event) {
            _vm.showChangeStore = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }