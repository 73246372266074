<template>
  <el-dialog
    title="选择店铺"
    :visible.sync="showChangeStore"
    :before-close="closeBox"
    width="30%"
  >
    <el-radio-group v-model="storeId">
      <el-radio
        v-for="(item,i) in storeIdList"
        :key="i"
        style="margin-bottom: 10px;"
        :label="item.storeId"
      >
        {{ item.storeName }}
      </el-radio>
    </el-radio-group>
    <div v-if="!storeId">
      暂无店铺，请先关联店铺
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="closeBox"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="toAdd"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ['showChangeStore', 'url', 'query'],
  data() {
    return {
      storeId: '',
      storeIdList: []
    }
  },
  mounted() {
    this.storeId = JSON.parse(localStorage.getItem('userInfo')).storeList[0] ? JSON.parse(localStorage.getItem('userInfo')).storeList[0].storeId : null
    this.storeIdList = JSON.parse(localStorage.getItem('userInfo')).storeList
  },
  methods: {
    toAdd() {
      if (!this.storeId) {
        this.$message({ type: 'error', message: '暂无店铺，请先关联店铺' })
        return
      }
      this.$router.push({
        path: this.url,
        query: {
          ...this.query,
          storeId: this.storeId
        }
      })
      this.$emit('closeBox')
    },
    closeBox() {
      this.$emit('closeBox')
    }
  }
}
</script>

<style>

</style>
