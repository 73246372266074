var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            size: "small",
            "header-cell-style": {
              background: "#d9dde1",
              color: "#0d0202",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "模板id", width: "80" },
          }),
          _c("el-table-column", { attrs: { prop: "name", label: "模板名称" } }),
          _c("el-table-column", {
            attrs: { prop: "selfName", label: "自提点名称" },
          }),
          _c("el-table-column", {
            attrs: { prop: "phone", label: "自提点电话" },
          }),
          _c("el-table-column", {
            attrs: { prop: "address", label: "自提点位置" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        staticClass: "margin_right_10",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.operation("edit", scope.row)
                          },
                        },
                      },
                      [_vm._v(" 修改 ")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticClass: "margin_right_10",
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.operation("delete", scope.row)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }